import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'
import { Flex } from 'rebass'
import { useQueryParam, StringParam } from 'use-query-params'
import { useLazyQuery } from '@apollo/client'
import CryptoJS from 'crypto-js'

import Layout from 'components/layout'
import SEO from 'components/seo'
import LottieShape from 'components/animations/LottieShape'
import TeamAnimation from 'components/animations/abstract/TeamAnimation'
import SignInUI from 'components/SignInUI'
import UserContext from 'context/user/UserContext'
import {
  CHECK_IF_MEETING_EXISTS,
  GET_MEETING_DYNAMIC_LINK,
} from 'services/graphql'

const CustomInvite = (props) => {
  const { t } = useTranslation('invite')
  const [aud, setAud] = useState('')
  const [meetingPermissions, setMeetingPermissions] = useState('')
  const { user, isAuthInitialized } = useContext(UserContext)
  const [meetingData] = useQueryParam('data', StringParam)
  const [
    getUserMeetingDynamicLink,
    {
      data: getUserMeetingDynamicLinkData,
      error: getUserMeetingDynamicLinkError,
    },
  ] = useLazyQuery(GET_MEETING_DYNAMIC_LINK)
  const [
    checkIfMeetingExists,
    { data: checkIfMeetingExistsData, error: checkIfMeetingExistsError },
  ] = useLazyQuery(CHECK_IF_MEETING_EXISTS)
  const siteTitle = props.data.site.siteMetadata.title

  const isMeetingDataValid =
    typeof meetingData !== 'undefined' && meetingData !== ''

  const isInvalidMeeting =
    checkIfMeetingExistsData && !checkIfMeetingExistsData.checkIfMeetingExists

  const isQueryError =
    checkIfMeetingExistsError || getUserMeetingDynamicLinkError

  const UnauthorizedUserFragment = (
    <SignInUI heading={t('unauthorizedHeading')} signInSuccessUrl="" />
  )

  const MissingMeetingDataQueryParameterFragment = (
    <h1>{t('missingQueryParamMessage')}</h1>
  )

  const ErrorFragment = <h1>{t('genericErrorMessage')}</h1>

  const AuthorizedUserFragment = (
    <>
      <h1>
        {t('greeting', {
          name: user && user.displayName ? user.displayName : null,
        })}
      </h1>
      <Flex flexWrap="wrap-reverse" alignContent="center" alignItems="center">
        <LottieShape animationData={TeamAnimation} size={325} />
        <h2>{t('joiningMeeting')}</h2>
      </Flex>
    </>
  )

  // check if the meeting exists on page load
  useEffect(() => {
    const decodeAndSetMeetingData = () => {
      // Decrypt and sanitize string
      let decodedURIMeetingData
      let decodedData

      try {
        decodedURIMeetingData = decodeURIComponent(meetingData)

        const bytes = CryptoJS.AES.decrypt(
          decodedURIMeetingData,
          process.env.GATSBY_CUSTOM_INVITE_SECRET,
        )
        decodedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      } catch (error) {
        console.error(error)
      }

      if (
        decodedData !== undefined &&
        decodedData !== null &&
        decodedData.aud !== null &&
        decodedData.permissions !== null
      ) {
        setAud(decodedData.aud)
        setMeetingPermissions(decodedData.permissions)
      }
    }

    if (typeof meetingData !== 'undefined' && meetingData !== '') {
      decodeAndSetMeetingData()
    }
  }, [meetingData, isMeetingDataValid])

  // check if the meeting exists on page load
  useEffect(() => {
    const fetchCheckIfMeetingExists = async () => {
      await checkIfMeetingExists({
        variables: { meetingId: aud },
      })
    }

    if (user && user.uid && isMeetingDataValid) {
      fetchCheckIfMeetingExists()
    }
    // eslint-disable-next-line
  }, [user, isMeetingDataValid])

  // fetch the meeting dynamic link
  useEffect(() => {
    const fetchUserMeetingDynamicLink = async () => {
      await getUserMeetingDynamicLink({
        variables: {
          sub: user && user.uid ? user.uid : null,
          aud,
          permissions: meetingPermissions,
        },
      })
    }

    if (
      isAuthInitialized &&
      user &&
      isMeetingDataValid &&
      checkIfMeetingExistsData &&
      checkIfMeetingExistsData.checkIfMeetingExists &&
      checkIfMeetingExistsData.checkIfMeetingExists.id &&
      aud !== ''
    ) {
      fetchUserMeetingDynamicLink()
    }
  }, [
    user,
    isAuthInitialized,
    isMeetingDataValid,
    checkIfMeetingExistsData,
    getUserMeetingDynamicLink,
    meetingPermissions,
    aud,
  ])

  // redirect user to the meeting dynamic link
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      getUserMeetingDynamicLinkData &&
      getUserMeetingDynamicLinkData.meetingDynamicLink
    ) {
      window.location.assign(getUserMeetingDynamicLinkData.meetingDynamicLink)
    }
  }, [getUserMeetingDynamicLinkData])

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title={t('customTitle')} />
      {!isAuthInitialized ? <h3>{t('loading')}</h3> : null}
      {(!isMeetingDataValid || isInvalidMeeting) && isAuthInitialized
        ? MissingMeetingDataQueryParameterFragment
        : null}
      {isAuthInitialized && !user && isMeetingDataValid
        ? UnauthorizedUserFragment
        : null}
      {isAuthInitialized && user && isMeetingDataValid && !isInvalidMeeting
        ? AuthorizedUserFragment
        : null}
      {isQueryError && isMeetingDataValid ? ErrorFragment : null}
    </Layout>
  )
}

export default CustomInvite

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
